import theme, { bitter, publicSans, redHatDisplay, shouldSkipGeneratingVar } from '@/theme/theme';
import CssBaseline from '@mui/joy/CssBaseline';
import { CssVarsProvider } from '@mui/joy/styles';
import {
  THEME_ID as MATERIAL_THEME_ID,
  Experimental_CssVarsProvider as MaterialCssVarsProvider,
  experimental_extendTheme as materialExtendTheme,
} from '@mui/material/styles';
import Head from 'next/head';
import { useRouter } from 'next/router';
import Script from 'next/script';
import queryString from 'query-string';
import * as React from 'react';
import '@/theme/styles.css';
import { LoadingProvider } from '@/layouts/LoadingProvider';

export default function App({ Component, pageProps }) {
  const router = useRouter();
  const { chromeless } = router.query;

  const isChromeless = chromeless === '1';
  const getLayout = Component.getLayout || ((page) => page);

  const tag_manager_id = process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_GTAG_ID;
  const development_tag_manager_id = process.env.NEXT_PUBLIC_DEVELOPMENT_GOOGLE_TAG_MANAGER_GTAG_ID;

  const materialTheme = materialExtendTheme(
    {
      palette: {
        mode: 'light',
      },
    },
    []
  );

  React.useEffect(() => {
    const handleRouteChange = (url) => {
      if (window.gtag) {
        let payload = {
          page_location: url,
        };

        window.gtag('event', 'page_view', payload);
      }
    };

    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  // Derive the app argument string from the current path and query string, and display "Open in App" toolbar on iOS devices
  const currentPath = router.asPath.split('?')[0];
  const queryStr = queryString.stringify(router.query);
  const appArgumentStr = `groupthink://${currentPath.substr(1)}${
    queryStr.length > 0 ? '?' + queryStr : ''
  }`;

  return (
    <>
      <Head>
        <meta name="viewport" content="initial-scale=1, width=device-width" />
        <meta
          name="apple-itunes-app"
          content={`app-id=6446509018, app-argument=${appArgumentStr}`}></meta>
        {isChromeless && (
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
          />
        )}
      </Head>
      {tag_manager_id && (
        <>
          <Script
            dangerouslySetInnerHTML={{ __html: `window.dataLayer = window.dataLayer || [];` }}
          />
          <Script
            dangerouslySetInnerHTML={{
              __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://metrics.groupthink.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','${tag_manager_id}');`,
            }}
          />
          <noscript
            dangerouslySetInnerHTML={{
              __html: `<iframe
                src="https://metrics.groupthink.com/ns.html?id=${tag_manager_id}"
                height="0"
                width="0"
                style="display: none; 
                visibility: hidden;"
                ></iframe>`,
            }}
          />
        </>
      )}
      {development_tag_manager_id && (
        <>
          <Script
            dangerouslySetInnerHTML={{ __html: `window.dataLayer = window.dataLayer || [];` }}
          />
          <Script
            dangerouslySetInnerHTML={{
              __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
              new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
              j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
              'https://www.googletagmanager.com/gtm.js?id='+i+dl+ 
              '&gtm_auth=${process.env.NEXT_PUBLIC_DEVELOPMENT_GOOGLE_TAG_MANAGER_AUTH}&gtm_preview=${process.env.NEXT_PUBLIC_DEVELOPMENT_GOOGLE_TAG_MANAGER_ENV_ID}&gtm_cookies_win=x';f.parentNode.insertBefore(j,f);
              })(window,document,'script','dataLayer','${development_tag_manager_id}');`,
            }}
          />
          <noscript
            dangerouslySetInnerHTML={{
              __html: `<iframe
                src="https://www.googletagmanager.com/ns.html?id=${development_tag_manager_id}&gtm_auth=${process.env.NEXT_PUBLIC_DEVELOPMENT_GOOGLE_TAG_MANAGER_AUTH}&gtm_preview=${process.env.NEXT_PUBLIC_DEVELOPMENT_GOOGLE_TAG_MANAGER_ENV_ID}&gtm_cookies_win=x"
                height="0"
                width="0"
                style="display: none; 
                visibility: hidden;"
                ></iframe>`,
            }}
          />
        </>
      )}

      <MaterialCssVarsProvider theme={{ [MATERIAL_THEME_ID]: materialTheme }}>
        <CssVarsProvider theme={theme} shouldSkipGeneratingVar={shouldSkipGeneratingVar}>
          <CssBaseline />
          <LoadingProvider>{getLayout(<Component {...pageProps} />)}</LoadingProvider>
        </CssVarsProvider>
      </MaterialCssVarsProvider>
    </>
  );
}
